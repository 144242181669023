'use strict'

import * as _ from 'lodash'
import {
  REQUIRED_FIELD_ERROR,
  INVALID_EMAIL_ERROR,
  NOT_EQUAL_ERROR,
  LENGTH_ERROR,
  PASSWORD_CASE_ERROR,
  PASSWORD_NUMBER_ERROR,
  PASSWORDS_DIFFERENT_ERROR,
  END_DATE_NOT_VALID,
  INVALID_DATE_FORMAT,
  INVALID_PERCENTAGE_ERROR,
  INVALID_INTEGER_ERROR,
  INVALID_UUID_ERROR,
  INVALID_THRESHOLD_ERROR,
} from 'app/frontend/helpers/validation-messages'

import * as moment from 'moment'
import { MaxDurationSettingType } from 'app/frontend/pages/material/teach/assessment-builder/settings-modal/form-fields/helper'

/**
 * Validation Helper.
 * Contains functions to make it easy to validate data.
 */
/**
 * Check if a value is not empty
 */
export function isNotEmpty(value) {
  if (_.isArray(value)) {
    // check array not empty
    return value.length > 0
  } else if (_.isString(value)) {
    // check string not empty
    return value.length > 0
  } else if (value !== undefined && value !== null) {
    // check value is set
    return true
  } else {
    return false
  }
}

/**
 * Checks if an email is valid.
 * Source: jQuery Validation
 */
export function isValidEmail(email: string): boolean {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  )
}

/**
 * Checks if a url is valid.
 * Source: jQuery Validation
 */
export function isValidUrl(url: string): boolean {
  return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
    url
  )
}

/**
 * Check if a url is valid Knerd Course Copy Link
 * url.
 * Must be in format:
 * /c/copy/<uuid>
 */
export function isValidKnerdCopyLink(url: string): boolean {
  return (
    isValidUrl(url) &&
    /\/c\/copy\/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}\/?$/.test(
      url
    )
  )
}

/**
 * Loosely check if a domain is valid. A completely correct validation is very complicated,
 * so for the sake of simplicity this check is slightly less restrictive than it could be.
 */
export function isValidDomain(domain: string): boolean {
  return (
    domain &&
    domain.length <= 255 &&
    !domain.startsWith('-') &&
    !domain.endsWith('-') &&
    /^[0-9a-z-]{1,63}(\.[0-9a-z]{1,63})+$/.test(domain)
  )
}

/**
 * Checks if a string is longer than nb characters.
 */
export function isLongerThan(text: string, nb: number): boolean {
  return text.length > nb
}

/**
 * Checks if a string is not empty and if it's not solely whitespaces
 */
export function isPopulated(value: any): boolean {
  return !!(value && value.toString().trim())
}

/**
 * Checks if a string is shorter than nb characters.
 */
export function isShorterThan(text: string, nb: number): boolean {
  return text.length < nb
}

export function validateRequired(value: any): string | undefined {
  if (!this.isPopulated(value)) {
    return REQUIRED_FIELD_ERROR
  }
}

export function isValidUuid(value?: string): boolean {
  return /^[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}$/.test(value)
}

export function validateUuid(value?: string, errorMsg?: string): string | undefined {
  if (!this.isValidUuid(value)) {
    return errorMsg || INVALID_UUID_ERROR
  }
}

/**
 * Checks if the value is between 0 and 100 (inclusive).
 */
export function isValidPercentage(value?: number): boolean {
  return value >= 0 && value <= 100
}

export function validatePercentage(value?: number): string | undefined {
  if (!this.isValidPercentage(value)) {
    return INVALID_PERCENTAGE_ERROR
  }
  if (!Number.isInteger(value)) {
    return INVALID_INTEGER_ERROR
  }
}

/**
 * Checks if the threshold is between 75 and 100 (inclusive).
 */
export function isValidThreshold(value?: number): boolean {
  return value >= 75 && value <= 100
}

export function validateMasteryThreshold(value?: number): string | undefined {
  if (!isValidThreshold(value)) {
    return INVALID_THRESHOLD_ERROR
  }
  if (!Number.isInteger(value)) {
    return INVALID_INTEGER_ERROR
  }
}

export function isValidAddress(value: string): boolean {
  if (!value) {
    return false
  }

  return /^[\w\.]+( [\w\.]+)+$/.test(value)
}

export function isValidUSZipCode(value: string): boolean {
  return /^[0-9]{5}(?:-[0-9]{4})?$/.test(value)
}

export function isValidPhoneNumber(value: string): boolean {
  return /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value)
}

export function validateEmail(value: string): string | undefined {
  if (!value) {
    return REQUIRED_FIELD_ERROR
  } else if (!this.isValidEmail(value)) {
    return INVALID_EMAIL_ERROR
  }
}

export function validateEquals(value: any, otherValue: any, otherName: string): string | undefined {
  if (value !== otherValue) {
    return NOT_EQUAL_ERROR(otherName)
  }
}

export function validatePassword(value: string): string | undefined {
  if (!/[A-Z]+/.test(value) || !/[a-z]+/.test(value)) {
    return PASSWORD_CASE_ERROR
  } else if (!/[0-9]+/.test(value)) {
    return PASSWORD_NUMBER_ERROR
  } else if (value.length < 8) {
    return LENGTH_ERROR(8)
  }
}

export function validatePasswordsEqual(value1: any, value2: any): string | undefined {
  if (value1 !== value2) {
    return PASSWORDS_DIFFERENT_ERROR
  }
}

export function validateMomentDateRange(
  startsAt: moment.Moment,
  endsAt: moment.Moment
): string | undefined {
  if (startsAt && endsAt && startsAt.isSameOrAfter(endsAt)) {
    return END_DATE_NOT_VALID
  }
}

/**
 * Checks if  maxDuration is lower or higher than 5 minutes
 */
export function validateAssessmentTime(maxDuration: MaxDurationSettingType): boolean {
  const time = moment
    .duration({
      hours: maxDuration.hours(),
      minutes: maxDuration.minutes(),
    })
    .asMinutes()
  return time < 5
}

export function shouldErrorOnNewEndsAt(initialEndsAt: number, newEndsAt: number): boolean {
  const now = moment.now()
  return newEndsAt < now && newEndsAt !== initialEndsAt
}

export function shouldWarnOnNewEndsAt(initialEndsAt: number, newEndsAt: number): boolean {
  const now = moment.now()
  return initialEndsAt < now && now < newEndsAt
}

export function validateUnique(
  value: string,
  existingValues: string[],
  errorMsg: string
): string | undefined {
  if (!value || !_.trim(value)) {
    return REQUIRED_FIELD_ERROR
  }

  if (_.includes(existingValues, value)) {
    return errorMsg
  }
}
export function validateUniqueDeep(
  value: any,
  existingValues: any[],
  errorMsg: string
): string | undefined {
  if (_.isEmpty(value)) {
    return REQUIRED_FIELD_ERROR
  }

  const match = _.find(existingValues, existingItem => {
    return _.isEqual(existingItem, value)
  })

  if (match) {
    return errorMsg
  }
}

export function validateMMDDYYYY(value: string): string | undefined {
  if (!moment(value, 'MM/DD/YYYY', true).isValid()) {
    return INVALID_DATE_FORMAT
  }
}

/**
 * Helper method for determining if an error should be displayed on a redux-form
 *  element.
 *
 * @param {object} fieldMeta: The meta object from a redux-form Field
 */
export function shouldRenderReduxFormError(fieldMeta: any): boolean {
  return !fieldMeta.active && fieldMeta.dirty && fieldMeta.error
}

export default {
  isNotEmpty,
  isValidEmail,
  isValidUrl,
  isValidKnerdCopyLink,
  isValidDomain,
  isLongerThan,
  isPopulated,
  isShorterThan,
  validateRequired,
  isValidAddress,
  isValidUSZipCode,
  isValidPhoneNumber,
  validateEmail,
  validateEquals,
  validatePassword,
  validatePasswordsEqual,
  shouldWarnOnNewEndsAt,
  shouldErrorOnNewEndsAt,
  validateUnique,
  validateUniqueDeep,
  validateMMDDYYYY,
  shouldRenderReduxFormError,
}
